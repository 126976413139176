import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import quoteFilled from "../images/finops/quote-filled.png"

export default class FinOpsFactsCarousel extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3500,
      arrows: true,
      dots: true,
    }

    const { slides } = this.props

    return (
      <>
        <div className="fin-facts-wrapper" style={{ maxWidth: "100%" }}>
          <Slider {...settings}>
            {slides.map((slide, index) => (
              <div key={index} className="flex col">
                <img src={quoteFilled} />
                <h3>{slide.heading}</h3>
                <p style={{ fontWeight: "bold" }}>{slide.author}</p>
                <p>{slide.text}</p>
              </div>
            ))}
          </Slider>
        </div>
      </>
    )
  }
}
