import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default class TextCarousel extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3500,
      dots: true,
      arrows: false,
    }

    const { texts } = this.props

    return (
      <div className="text-carousel-wrapper">
        <Slider {...settings}>
          {texts.map((text, index) => (
            <div key={index}>
              <div style={{ display: "flex" }}>
                <h4 className="text-carousel-pr">{text}</h4>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}
