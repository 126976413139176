import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Button } from "../components/Button"
import ButtonB from "../components/ButtonB"
import ServicesAccordion from "../components/Accordion/ServicesAccordion"
import "../styles/automation-and-intelligence.scss"
import "../styles/finops.scss"
import ScrollAnimation from "react-animate-on-scroll"
import ContactForm from "../components/ContactForm"
import LogoCarousel from "../components/LogoCarousel"

//Images

import MapImg from "../images/map.png"
import { FaCircle, FaRegCircle } from "react-icons/fa"

import optimise from "../images/finops/Optimise circle.png"
import inform from "../images/finops/Inform circle.png"
import operate from "../images/finops/Operate circle.png"

import CloudRate from "../images/Cloud rate.svg"
import CloudUsage from "../images/Cloud usage.svg"
import Optimisation from "../images/Optimisation.svg"
import Organisational from "../images/Organisational.svg"
import PerformanceTracking from "../images/Performance tracking.svg"
import RealTime from "../images/Real time.svg"

import greenCheck from "../images/finops/Green ticks.svg"
//data for accordion

import quoteFilled from "../images/finops/quote-filled.png"
import quoteOutlined from "../images/finops/outlined-quote.png"
import blueLine from "../images/finops/blue-line.svg"

import one from "../images/finops/Stakeholder.webp"
import two from "../images/finops/Data gathering.webp"
import three from "../images/finops/Process Evaluation.webp"
import four from "../images/finops/Recommendations.webp"
import five from "../images/finops/Customised Roadmap.webp"
import TextCarousel from "../components/TextCarousel"
import FinOpsFactsCarousel from "../components/FinOpsFactsCarousel"

import downArrow from "../lotties/arrow-down.json"
import Lottie from "react-lottie-player/dist/LottiePlayerLight"

const FinOps = () => {
  return (
    <Layout>
      <SEO
        title="FinOps Practice"
        description="Metsi Technologies streamlines cloud financial management with FinOps, optimising costs, enhancing value, and promoting an agile, effective IT infrastructure."
        keywords={[
          "cloud financial management",
          "finops service provider",
          "finops consultancy",
        ]}
        lang="en-gb"
      />

      {/********* Hero Section ***********/}
      <div className="finops-bkg">
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <ScrollAnimation
                    animateIn="animate__fadeInLeft"
                    duration="0.75"
                  >
                    <h1>FinOps Practice</h1>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInRight"
                    duration="0.75"
                  >
                    <h2 style={{ width: "100%" }}>
                      MAXIMISE THE BUSINESS VALUE OF YOUR CLOUD INVESTMENTS BY
                      BRIDGING THE GAP BETWEEN FINANCE AND ENGINEERING TEAMS
                      WITH FINOPS BEST PRACTICES.
                    </h2>{" "}
                  </ScrollAnimation>
                  <a href="#services">
                    <Lottie
                      loop
                      animationData={downArrow}
                      play
                      style={{ width: 120, height: 120 }}
                      className="scroll-arrow"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/********* Understand, manage, scale ***********/}
      <div className="section services" id="services">
        <div className="inner-container">
          <br />
          <ScrollAnimation animateIn="animate__fadeInLeft" duration="0.75">
            {" "}
            <h3 style={{ textTransform: "none" }} id="ums-h3">
              Understand, manage and scale cost-effective multicloud operations
            </h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInRight" duration="0.75">
            <p>
              Without a robust cloud financial management framework in place,
              your organisation is spending more on licenses and services than
              needed. Without the expected benefits. Metsi's FinOps practice
              helps organisations of all sizes unlock the true value of cloud
              operations by helping them understand, manage and optimise their
              cloud spend.
            </p>
          </ScrollAnimation>
          <br />
        </div>
      </div>
      {/********* Silver Lining ***********/}
      <div
        className="section services"
        id="services"
        style={{ background: "rgb(243, 243, 243)" }}
      >
        <div className="grid-2" style={{ maxWidth: "100%" }} id="silver-lining">
          <ScrollAnimation animateIn="animate__fadeInLeft" duration="0.75">
            <div className="flex je w-100">
              <h3>The silver lining to your growing cloud operations.</h3>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInRight" duration="0.75">
            <div>
              <TextCarousel
                texts={[
                  "Growing operations mean growing cloud spend and complexity. Metsi's FinOps practice helps growing organisations scale their cloud operations sustainably by bridging the gap between finance and engineering.",
                  "Our team of skilled and passionate FinOps Foundation certified practitioners can help your organisation quickly understand and implement cloud financial management best practices.",
                  "Uncover actionable insights to help your organisation resolve sprawling cloud costs and complexities at speed. Achieve a measurable return on investment (ROI) with 'quick wins' you can implement within weeks.",
                ]}
              />
              <p></p>
              {/* <div id="long-button" style={{ justifyContent: "flex-start" }}>
                <ButtonB>Download FinOps maturity checklist</ButtonB>
              </div> */}
            </div>
          </ScrollAnimation>
        </div>
      </div>
      {/********* Facts ***********/}
      <div className="section services" id="services">
        <ScrollAnimation animateIn="animate__fadeInUp" duration="0.75">
          {" "}
          <div className="inner-container">
            <FinOpsFactsCarousel
              slides={[
                {
                  heading:
                    "IN 2023, OVER 72% OF GLOBAL ORGANISATIONS HAVE NO OR LIMITED VISIBILITY INTO CLOUD VALUE.",
                  author: "Mckinsey",
                  text:
                    "The FinOps way: How to avoid the pitfalls to realising cloud’s value",
                },
                {
                  heading:
                    "62% OF ORGANISATIONS WHO MANAGE LESS THAN $1M DO NOT HAVE A FORMAL FINOPS FUNCTION.",
                  author: "FinOps Foundation",
                  text: "2023 State of FinOps",
                },
                {
                  heading:
                    "MANAGING CLOUD SPEND IS A TOP CLOUD PRIORITY IN 2023.",
                  author: "Flexera",
                  text: "2023 State of The Cloud Report",
                },
                // more slides here
              ]}
            />
          </div>
        </ScrollAnimation>
      </div>
      {/********* FinOps journey ***********/}
      <div
        className="section services"
        id="services"
        style={{ backgroundColor: "rgb(243, 243, 243)" }}
      >
        <div className="inner-container">
          <ScrollAnimation animateIn="animate__fadeInUp" duration="0.75">
            <br />
            <br />
            <h3>Guiding you on your FinOps journey</h3>
            <br />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" duration="1.25">
            <div className="grid-3" id="finops-journey">
              <div id="optimise">
                <img src={optimise} />
                <h3>Optimise</h3>
                <p>
                  Reduce costs and optimise your cloud footprint through
                  automation, right sizing and minimising wasteful resources.
                </p>
              </div>
              <div id="inform">
                <img src={inform} />
                <h3>Inform</h3>
                <p>
                  Empower your organisation with enhanced visibility,
                  allocation, benchmarking, budgeting and forecasting.
                </p>
              </div>
              <div id="operate">
                <img src={operate} />
                <h3>Operate</h3>
                <p>
                  Continuously evaluate business objectives and metrics and
                  build a strong culture of FinOps.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <br />
          <br />
          {/* <div id="long-button">
            <ButtonB>Download FinOps maturity assessment</ButtonB>
          </div> */}
        </div>
      </div>

      {/********* Capabilities ***********/}
      <div className="section services finops-capabilities" id="services">
        <div className="inner-container">
          <ScrollAnimation animateIn="animate__fadeInLeft" duration="0.75">
            <h3>Our capabilities</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInRight" duration="0.75">
            <h2>METSI’S FINOPS PRACTICE STRETCHES ACROSS 6 CORE DOMAINS.</h2>
          </ScrollAnimation>
        </div>
        <div className="row" style={{ maxWidth: "100%" }}>
          <ScrollAnimation animateIn="animate__fadeInUp" duration="0.75">
            <div
              className="inner-container-3 flip-green-wrapper"
              style={{ maxWidth: "180px", minWidth: "180px" }}
            >
              <div className="flip-green">
                <img src={CloudUsage} className="icon" />
              </div>

              <h2 className="droplet-header">Understand cloud usage & cost</h2>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            delay={125}
            duration="0.75"
          >
            <div
              className="inner-container-3 flip-green-wrapper"
              style={{ maxWidth: "180px", minWidth: "180px" }}
            >
              <div className="flip-green">
                <img src={PerformanceTracking} className="icon" />
              </div>

              <h2 className="droplet-header">
                Performance tracking & benchmarking
              </h2>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            delay={250}
            duration="0.75"
          >
            <div
              className="inner-container-3 flip-green-wrapper"
              style={{ maxWidth: "180px", minWidth: "180px" }}
            >
              <div className="flip-green">
                <img src={RealTime} className="icon" />
              </div>

              <h2 className="droplet-header">Real-time decision making</h2>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            delay={375}
            duration="0.75"
          >
            <div
              className="inner-container-3 flip-green-wrapper"
              style={{ maxWidth: "180px", minWidth: "180px" }}
            >
              <div className="flip-green">
                <img src={CloudRate} className="icon" />
              </div>

              <h2 className="droplet-header">Cloud rate optimisation</h2>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            delay={500}
            duration="0.75"
          >
            <div
              className="inner-container-3 flip-green-wrapper"
              style={{ maxWidth: "180px", minWidth: "180px" }}
            >
              <div className="flip-green">
                <img src={Optimisation} className="icon" />
              </div>

              <h2 className="droplet-header">Cloud usage optimisation</h2>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            delay={625}
            duration="0.75"
          >
            <div
              className="inner-container-3 flip-green-wrapper"
              style={{ maxWidth: "180px", minWidth: "180px" }}
            >
              <div className="flip-green">
                <img src={Organisational} className="icon" />
              </div>

              <h2 className="droplet-header">Organisational alignment</h2>
            </div>
          </ScrollAnimation>
        </div>
      </div>

      {/********* Case study ***********/}
      <div className="section services fin-case" id="services">
        <div className="inner-container">
          <ScrollAnimation animateIn="animate__fadeInRight" duration="0.75">
            <h2>CASE STUDY</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInLeft" duration="0.75">
            {" "}
            <h3>Accelerated time to value and measurable ROI</h3>
          </ScrollAnimation>
          <br />
          <br />
          <ScrollAnimation animateIn="animate__fadeIn" duration="1.25">
            <div className="grid-2" id="case-study">
              <div className="overview flex col as">
                <h3>Overview</h3>
                <img src={blueLine} />
                <p style={{ fontWeight: "bold" }}>
                  Metsi collaborated with a leading international outsourcing
                  organisation to address visibility and cost management
                  challenges in its recent Microsoft Azure public cloud
                  migration.
                </p>
                <p>
                  Within 8 weeks of the engagement, the company which had
                  invested heavily into a new cloud architecture that was
                  costing significantly more than the initial migration
                  forecasts had predicted, saw a 12% saving on cloud spend
                </p>
                <div id="long-button" style={{ justifyContent: "flex-start" }}>
                  <ButtonB
                    pulser
                    href="https://blog.metsi.com/metsi-technologies-accelerates-roi-and-finops-capabilities-for-global-outsourcing-organisation/"
                  >
                    Read full case study
                  </ButtonB>
                </div>{" "}
              </div>
              <div className="glance flex col as">
                <h3>At a glance</h3>
                <div className="grid-2">
                  <div className="glance-item">
                    <img src={greenCheck} />
                    <p>
                      A 12% initial saving on cloud spend within two months of
                      engagement
                    </p>
                  </div>
                  <div className="glance-item">
                    <img src={greenCheck} />
                    <p>Return on investment within six weeks</p>
                  </div>
                  <div className="glance-item">
                    <img src={greenCheck} />
                    <p>
                      Roadmap for increased cost visibility and FinOps
                      capabilities
                    </p>
                  </div>
                  <div className="glance-item">
                    <img src={greenCheck} />
                    <p>20% cloud spend saving anticipated within 12 months</p>
                  </div>
                </div>
                <div className="blue-text">
                  <img src={quoteOutlined} />
                  <img src={quoteOutlined} />
                  <p>
                    Metsi’s team brought us the skillsets and capabilities we
                    needed to resolve the problems we were having with our
                    recent migration to Azure public cloud. Our spend was higher
                    than the initial migration plan forecasts and we wanted more
                    visibility and better control so we could achieve our
                    expected ROI and strategic objectives.
                  </p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>

      {/*********** Guide organisation *************/}
      <div
        className="section services"
        id="services"
        style={{ background: "rgb(243, 243, 243)" }}
      >
        <div className="inner-container" id="finops-success">
          <ScrollAnimation animateIn="animate__fadeInLeft" duration="0.75">
            <br />
            <br />
            <div className="flex jc w-100">
              <h3>Guide your organisation to FinOps success, step-by-step.</h3>
            </div>{" "}
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInRight" duration="0.75">
            <p>
              Let Metsi guide you to achieving a robust FinOps culture and
              cross-team collaboration. Through our consultation services, we
              work with you to develop an actionable roadmap tailored to your
              needs, and bring all organisational stakeholders to the table to
              ensure meaningful implementation.
            </p>
          </ScrollAnimation>

          <div className="finops-process-wrapper">
            <div className="grid-5">
              <ScrollAnimation animateIn="animate__fadeInUp" duration="0.75">
                <div className="finops-roadmap-number">
                  <img src={one} />
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                delay={125}
                duration="0.75"
              >
                <div className="finops-roadmap-number">
                  <img src={two} />
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                delay={250}
                duration="0.75"
              >
                <div className="finops-roadmap-number">
                  <img src={three} />
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                delay={375}
                duration="0.75"
              >
                <div className="finops-roadmap-number">
                  <img src={four} />
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                delay={500}
                duration="0.75"
              >
                <div className="finops-roadmap-number">
                  <img src={five} />
                </div>
              </ScrollAnimation>
            </div>
            <div className="finops-roadmap-arrow"></div>{" "}
            <div className="grid-5">
              <div className="finops-roadmap-text">
                STAKEHOLDER INTERVIEWS + WORKSHOPS
              </div>
              <div className="finops-roadmap-text">DATA GATHERING</div>
              <div className="finops-roadmap-text">PROCESS EVALUATION</div>
              <div className="finops-roadmap-text">RECOMMENDATIONS</div>
              <div className="finops-roadmap-text">CUSTOMISED ROADMAP</div>
            </div>
            <div id="long-button">
              <ButtonB href="#contact">Speak to a finops expert</ButtonB>
            </div>
          </div>
        </div>
      </div>

      {/*********** Partners *************/}
      <div className="section partners">
        <div className="outer-container" style={{ display: "block" }}>
          <div
            className="droplet-blue-invert"
            style={{ marginTop: "25px", float: "left" }}
          >
            Our Partners
          </div>
          <div style={{ display: "block" }}>
            <LogoCarousel />
          </div>
        </div>
      </div>
      {/*********** Contact *************/}
      <div className="contact-section" id="contact">
        <div className="outer-container">
          <div className="contact-header" id="reclaim">
            <ScrollAnimation animateIn="animate__fadeInUp" duration="0.75">
              <h1
                style={{ textAlign: "center", color: "#fff", margin: "20px" }}
              >
                Ready to reclaim your cloud spend?
              </h1>
            </ScrollAnimation>
            <div id="long-button">
              <ButtonB href="#contact-form">Get in touch today</ButtonB>
            </div>
            <br />
          </div>
          <a id="contact-form" />
          <br />
          <br />
          <div className="row">
            <div className="inner-container-2">
              <ContactForm />
            </div>
            <div className="inner-container-2">
              <div className="row">
                <div className="inner-container">
                  <img src={MapImg} alt="map" style={{ width: "100%" }} />
                </div>
              </div>
              <div className="row">
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Office Locations</h4>
                  <ul>
                    <li>
                      <FaCircle className="icon" id="uk" />
                      UK
                    </li>
                    <li>
                      <FaCircle className="icon" id="usa" />
                      USA
                    </li>
                    <li>
                      <FaCircle className="icon" id="germany" />
                      Germany
                    </li>
                    <li>
                      <FaCircle className="icon" id="netherlands" />
                      The Netherlands
                    </li>
                    <li>
                      <FaCircle
                        className="icon"
                        id="india"
                        style={{ color: "transparent" }}
                      />
                    </li>
                  </ul>
                </div>
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Satellite Locations</h4>
                  <ul>
                    <li>
                      <FaRegCircle className="icon" id="belgium" />
                      Belgium
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="sa" />
                      South Africa
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="poland" />
                      Poland
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="spain" />
                      Spain
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="india" />
                      India
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FinOps
